<template>
  <div class="Content">
    <loading :showflag="onion"></loading>
    <ul class="biscuit"
        v-for="(item,index) in Petrichor"
        :key="index">
      <li>
        <span>{{item.title}}</span>
      </li>
      <li>
        <ve-histogram :tooltip="item.tooltip"
                      :tooltip-visible='false'
                      :grid="item.grid"
                      :xAxis="item.xAxis"
                      :yAxis="item.yAxis"
                      :series="item.series"
                      :settings="item"
                      :colors="item.color"></ve-histogram>
      </li>
    </ul>
  </div>
</template>
<script>
import VeHistogram from 'v-charts/lib/histogram.common'
import loading from '@/pages/Search/compoments/loading'
export default {
  components: { VeHistogram, loading },
  props: ['data'],
  data () {
    // 柱状图图表参数
    this.chartSettings = {
      color: ['#4B9EFB'],
      grid: {
        left: '3%',
        right: '0%',
        top: '20',
        containLabel: true,
        height: 230,
        width: '90%'
      },
      xAxis: [
        {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: '直接访问',
          type: 'bar',
          data: [10, 52, 200],
          barWidth: 20,
          itemStyle: {
            barBorderRadius: [70, 70, 70, 70, 70]
          },
          label: {
            normal: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#4B9EFB',
                fontSize: 14,
                backgroundColor: 'white'
              },
              formatter (val) {
                // return ((val.value / sum) * 100).toFixed(2) + '%'
                return ((val.value / 267) * 100).toFixed(2) + '%'
              }
            }
          }
        }
      ]
    }
    return {
      breakfast: [],
      onion: 1
    }
  },
  computed: {
    Petrichor () {
      const res = []
      this.data.data.result.graph_data.forEach(item => {
        let sum = 0
        const opations = {
          color: ['#4B9EFB'],
          grid: {
            left: '3%',
            right: '0%',
            top: '20',
            containLabel: true,
            height: 230,
            width: '90%'
          },
          xAxis: [
            {
              type: 'category',
              data: [],
              axisTick: {
                alignWithLabel: true
              }
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: '直接访问',
              type: 'bar',
              data: [],
              barWidth: 20,
              itemStyle: {
                barBorderRadius: [70, 70, 70, 70, 70]
              },
              label: {
                normal: {
                  show: true,
                  position: 'top',
                  textStyle: {
                    color: '#4B9EFB',
                    fontSize: 14,
                    backgroundColor: 'white'
                  },
                  formatter (val) {
                    return sum === 0 ? '0.00%' : ((val.value / sum) * 100).toFixed(2) + '%'
                  }
                }
              }
            }
          ]
        }
        opations.xAxis[0].data = item.graph.name
        opations.series[0].data = item.graph.datas
        opations.title = item.title
        sum = item.graph.datas.reduce(function (one, two) {
          return one + two
        })
        res.push(opations)
      })
      // 分割线
      this.list()
      return res
    }
  },
  methods: {
    list (res) {
      this.onion = 2
    }
  },
  mounted () { }
}
</script>
<style lang="stylus">
.Content
  width 100%
  overflow hidden
  min-height 550px
  margin-top 20px
  .biscuit
    width 100%
    height 297px
    margin-top 20px
    margin-bottom 20px
    li:nth-child(1)
      color #333333
      font-size 15px
      display flex
      justify-content space-between
      width 100%
      height 20px
      line-height 20px
      margin-bottom 10px
      span:nth-child(2)
        color #999999
        font-size 13px
  .panli
    width 100%
    overflow hidden
    padding-bottom 20px
    .Hiraeth
      display flex
      justify-content space-between
      span
        color #333333
        font-size 15px
      span:nth-child(2)
        color #999999
        font-size 13px
    .Ineffable
      width 100%
      margin-top 10px
      li
        margin-bottom 10px
      div:nth-child(1)
        color #000000
        font-size 15px
      div:nth-child(2)
        margin-top 5px
        height 20px
        line-height 20px
        font-size 13px
        color #999999
        p:nth-child(1)
          width 30%
          height 20px
          white-space nowrap
          text-overflow ellipsis
          overflow hidden
          float left
        p:nth-child(2)
          width 45%
          height 20px
          white-space nowrap
          text-overflow ellipsis
          overflow hidden
          float left
        p:nth-child(3)
          width 20%
          white-space nowrap
          text-overflow ellipsis
          overflow hidden
          float left
</style>
