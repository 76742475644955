<template>
  <div>
    <div class="LawyerFen_contant">

      <ul class="LawyerFen">
        <loading :showflag="like"></loading>
        <li v-show="Iridescent">
          <span v-for="(item,index) in crayon"
                :key="index">
            <div class="IGH">
              <div>
                <span>{{item.name}} {{item.num}}</span>
                <i class="iconfont icon-gengduo-copy"></i>
              </div>
              <div>
                <span v-for="(items,indexs) in item.data"
                      :key="indexs">{{items.sub_name}} {{items.num}}</span>
              </div>
            </div>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import loading from '@/pages/Search/compoments/loading'
export default {
  props: ['data'],
  components: { loading },
  data () {
    return {
      Iridescent: true,
      Nefarious: false,
      like: 1
    }
  },
  mounted () {
    this.like = 2
  },
  computed: {
    crayon () {
      return this.data.data.result.graph_data
    },
    ruler () {
      return this.data.data.result.case_data
    }
  },
  methods: {
  }
}
</script>
<style scoped>
.panli {
  width: 100%;
  overflow: hidden;
  padding-bottom: 20px;
}
.Hiraeth {
  display: flex;
  justify-content: space-between;
}
.Hiraeth span {
  color: #333333;
  font-size: 15px;
}
.Hiraeth span:nth-child(2) {
  color: #999999;
  font-size: 13px;
}
.Ineffable {
  width: 100%;
  margin-top: 10px;
}
.Ineffable li {
  margin-bottom: 10px;
}
.Ineffable div:nth-child(1) {
  color: #000000;
  font-size: 15px;
}
.Ineffable div:nth-child(2) {
  margin-top: 5px;
  height: 20px;
  line-height: 20px;
  font-size: 13px;
  color: #999999;
}
.Ineffable div:nth-child(2) p:nth-child(1) {
  width: 30%;
  height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  float: left;
}

.Ineffable div:nth-child(2) p:nth-child(2) {
  width: 45%;
  height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  float: left;
}

.Ineffable div:nth-child(2) p:nth-child(3) {
  width: 20%;
  height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  float: left;
}

.Cause {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  color: #333333;
  margin-top: 10px;
}
.Cause p:nth-child(2) {
  font-size: 13px;
  color: #999999;
}
.CauseCase {
  width: 100%;
  margin-top: 10px;
}
.CauseCase li {
  margin-top: 10px;
}
.CauseCase li p:nth-child(2) {
  color: #999999;
  font-size: 13px;
}
.CauseCase li p:nth-child(1) {
  color: #000000;
  font-size: 15px;
}
.Lawyer {
  width: 100%;
  line-height: 27px;
  text-align: center;
  height: 27px;
  border-radius: 5px;
  display: flex;
  margin-top: 10px;
}
.Lawyer p:nth-child(1) {
  width: 50%;
  height: 27px;
  color: #4b9efb;
  border: 1px solid #4b9efb;
  border-radius: 5px 0px 0px 5px;
}
.Lawyer p:nth-child(2) {
  width: 50%;
  height: 27px;
  color: #666666;
  border: 1px solid #cccccc;
  border-left: none;
  border-radius: 0px 5px 5px 0px;
}
.LawyerFen_contant {
  width: 100%;
  margin-bottom: 20px;
}
.LawyerFen li {
  width: 100%;
  font-size: 15px;
  color: #000000;
  margin-top: 10px;
}
.TFG {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #f3f4f7;
  margin-bottom: 10px;
}
.IGH {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #f3f4f7;
  margin-bottom: 10px;
}
IGH .LawyerFen li div:nth-child(1) span {
  float: left;
}
.LawyerFen li div:nth-child(1) i {
  float: right;
}
.LawyerFen li div:nth-child(2) {
  font-size: 13px;
  color: #666666;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.load {
  margin-top: 10px;
}
</style>
