<template>
  <div>
    <div class="Lawyer">
      <p @click="Iridescent=true"
         :style="{'border-color':Iridescent?'#4b9efb':'#666666','color':Iridescent?'#4b9efb':'#666666'}">律所</p>
      <p @click="Iridescent=false"
         :style="{'border-color':!Iridescent?'#4b9efb':'#666666','color':!Iridescent?'#4b9efb':'#666666'}">律师</p>
    </div>
    <loading :showflag="Eraser"></loading>
    <div class="LawyerFen_contant">
      <ul class="LawyerFen">
        <li v-show="Iridescent">
          <span v-for="(item,index) in typeof this.eraser.showflag=='boolean'?(this.eraser.showflag?this.eraser:this.eraser.slice(0,3)):[]"
                :key="index">
            <div class="IGH">
              <div>
                <span>{{item.name}} {{item.num}}</span>
                <i class="iconfont icon-gengduo-copy"></i>
              </div>
              <div>
                <span v-for="(items,indexs) in item.data"
                      :key="indexs">{{items.sub_name}} {{items.num}}</span>
              </div>
            </div>
          </span>
          <div class="encounter"
               v-if="this.eraser.length>3"
               @click="changeflag()">{{this.eraser.showflag?'收起':'查看全部'}}</div>
        </li>

        <li v-show="!Iridescent">
          <span v-for="(item,index) in typeof this.mobile.flag=='boolean'?(this.mobile.flag?this.mobile:this.mobile.slice(0,3)):[]"
                :key="index">
            <div class="TFG">
              <div>
                <span>{{item.name}} {{item.num}}</span>
                <i class="iconfont icon-gengduo-copy"></i>
              </div>
              <div>
                <span v-for="(items,indexs) in item.data"
                      :key="indexs">{{items.sub_name}} {{items.num}}</span>
              </div>
            </div>
          </span>
          <div v-if="this.mobile.length>3"
               @click="debtflag()"
               class="encounter">{{this.mobile.flag?'收起':'查看全部'}}</div>
        </li>

      </ul>
    </div>
  </div>
</template>
<script>
import loading from '@/pages/Search/compoments/loading'
export default {
  props: ['data'],
  components: { loading },
  data () {
    return {
      Iridescent: true,
      Nefarious: false,
      Eraser: 1
    }
  },
  mounted () {
    this.Eraser = 2
  },
  computed: {
    eraser () {
      this.$set(this.data.data.result.graph_data[0].data, 'showflag', false)
      return this.data.data.result.graph_data[0].data
    },
    mobile () {
      this.$set(this.data.data.result.graph_data[1].data, 'flag', false)
      return this.data.data.result.graph_data[1].data
    },
    ruler () {
      return this.data.data.result.case_data
    }
  },
  methods: {
    changeflag () {
      this.$set(this.data.data.result.graph_data[0].data, 'showflag', !this.data.data.result.graph_data[0].data.showflag)
    },
    debtflag () {
      this.$set(this.data.data.result.graph_data[1].data, 'flag', !this.data.data.result.graph_data[1].data.flag)
    }
  }
}
</script>
<style scoped>
.LawyerFen {
  position: relative;
  padding-bottom: 10px;
}
.encounter {
  position: absolute;
  left: 50%;
  bottom: -10px;
  color: #999;
}
.global {
  position: absolute;
  left: 50%;
  bottom: -10px;
  color: #999;
}
.panli {
  width: 100%;
  overflow: hidden;
  padding-bottom: 20px;
}
.Hiraeth {
  display: flex;
  justify-content: space-between;
}
.Hiraeth span {
  color: #333333;
  font-size: 15px;
}
.Hiraeth span:nth-child(2) {
  color: #999999;
  font-size: 13px;
}
.Ineffable {
  width: 100%;
  margin-top: 10px;
}
.Ineffable li {
  margin-bottom: 10px;
}
.Ineffable div:nth-child(1) {
  color: #000000;
  font-size: 15px;
}
.Ineffable div:nth-child(2) {
  margin-top: 5px;
  height: 20px;
  line-height: 20px;
  font-size: 13px;
  color: #999999;
}
.Ineffable div:nth-child(2) p:nth-child(1) {
  width: 30%;
  height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  float: left;
}

.Ineffable div:nth-child(2) p:nth-child(2) {
  width: 45%;
  height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  float: left;
}

.Ineffable div:nth-child(2) p:nth-child(3) {
  width: 20%;
  height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  float: left;
}

.Cause {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  color: #333333;
  margin-top: 10px;
}
.Cause p:nth-child(2) {
  font-size: 13px;
  color: #999999;
}
.CauseCase {
  width: 100%;
  margin-top: 10px;
}
.CauseCase li {
  margin-top: 10px;
}
.CauseCase li p:nth-child(2) {
  color: #999999;
  font-size: 13px;
}
.CauseCase li p:nth-child(1) {
  color: #000000;
  font-size: 15px;
}
.Lawyer {
  width: 100%;
  line-height: 27px;
  text-align: center;
  height: 27px;
  border-radius: 5px;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.Lawyer p:nth-child(1) {
  width: 50%;
  height: 27px;
  color: #4b9efb;
  border: 1px solid #4b9efb;
  border-radius: 5px 0px 0px 5px;
}
.Lawyer p:nth-child(2) {
  width: 50%;
  height: 27px;
  color: #666666;
  border: 1px solid #cccccc;
  border-left: none;
  border-radius: 0px 5px 5px 0px;
}
.LawyerFen_contant {
  width: 100%;
  margin-bottom: 20px;
}
.LawyerFen li {
  width: 100%;
  font-size: 15px;
  color: #000000;
  margin-top: 10px;
}
.TFG {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #f3f4f7;
  margin-bottom: 10px;
}
.IGH {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #f3f4f7;
  margin-bottom: 10px;
}
IGH .LawyerFen li div:nth-child(1) span {
  float: left;
}
.LawyerFen li div:nth-child(1) i {
  float: right;
}
.LawyerFen li div:nth-child(2) {
  font-size: 13px;
  color: #666666;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
