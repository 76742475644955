<template>
  <div class="Sonorous">
    <loading :showflag="like"></loading>
    <div v-if="!selectArray"></div>
    <ul class="Limerence"
        v-for="(item,index) in Illicit"
        :key="index">
      <li>
        <span>{{item.title}}</span>
      </li>
      <li class="summary">
        <component :is="item.name"
                   :settings="item.chartSettings"
                   :series="item.chartSettings.series"
                   :legend="item.chartSettings.legend"
                   :colors="['#4B9EFB','#2DC1E7','#C0DCFB']"
                   :tooltip="item.chartSettings.tooltip"
                   :tooltip-visible='true'
                   :grid="item.chartSettings.grid"
                   :xAxis="item.chartSettings.xAxis"
                   :yAxis="item.chartSettings.yAxis"
                   :data="item.chartSettings"
                   :legend-visible='item.chartSettings.legend_visible'></component>
      </li>
    </ul>
  </div>
</template>
<script>
import VeRing from 'v-charts/lib/ring.common'
import VeHistogram from 'v-charts/lib/histogram.common'
import loading from '@/pages/Search/compoments/loading'
export default {
  props: ['data'],
  components: {
    VeRing, VeHistogram, loading
  },
  data () {
    return {
      Petrichor: [],
      like: 1,
      Illicit: [
        {
          name: 'VeHistogram',
          chartSettings: {
            color: ['#4B9EFB'],
            grid: {
              left: '3%',
              right: '0%',
              top: '20',
              containLabel: true,
              height: 200,
              width: '90%'
            },
            xAxis: [
              {
                type: 'category',
                data: [],
                axisTick: {
                  alignWithLabel: true
                }
              }
            ],
            yAxis: [
              {
                type: 'value'
              }
            ],
            series: [
              {
                name: '直接访问',
                type: 'bar',
                data: [],
                barWidth: 20,
                itemStyle: {
                  barBorderRadius: [70, 70, 70, 70, 70]
                },
                label: {
                  normal: {
                    show: true,
                    position: 'top',
                    textStyle: {
                      color: '#4B9EFB',
                      fontSize: 14,
                      backgroundColor: 'white'
                    },
                    formatter (val) {
                      return ((val.value / 267) * 100).toFixed(2) + '%'
                    }
                  }
                }
              }
            ]
          }
        }, {
          name: 'VeRing',
          chartSettings: {
            legend: {
              orient: 'vertical',
              x: '33%',
              y: '15%',
              icon: 'circle',
              textStyle: {
                color: '#333333',
                fontSize: 16,
                fontWeight: 500
              },
              formatter (val) {
                return val
              }
            },
            series: {
              type: 'pie',
              radius: ['60%', '45%'],
              center: ['50%', '28%'],
              data: [

              ],
              label: {
                position: 'center',
                show: false,
                fontSize: 14,
                color: '#333333',
                formatter: function (val, index) {
                  // num += val.data.value
                  if (val.dataIndex === 1) {
                    return '总计'
                    //  + num
                  } else {
                    return ''
                  }
                }
              },
              labelLine: {
                smooth: true,
                length: 10,
                length2: 7
              }
            }

          }

        }
      ]
    }
  },
  computed: {
    selectArray () {
      return this.list(this.data)
    }
  },
  methods: {
    list (res) {
      if (!res) {
        return []
      }
      res.data.result.graph_data.forEach((item, index) => {
        this.Illicit[index].title = item.title
      })
      let sum = 0
      res.data.result.graph_data[1].data[0].graph.datas.forEach((items, indexs) => {
        sum += items
      })
      this.Illicit[0].chartSettings.xAxis[0].data = res.data.result.graph_data[0].data.name
      this.Illicit[0].chartSettings.series[0].data = res.data.result.graph_data[0].data.datas

      res.data.result.graph_data[1].data[0].graph.name.forEach((item, index) => {
        this.Illicit[1].chartSettings.series.data.push({
          name: item + (res.data.result.graph_data[1].data[0].graph.datas[index] / sum * 100).toFixed() + '%',
          value: res.data.result.graph_data[1].data[0].graph.datas[index]
        })
      })
      if (res.data.result.graph_data[1].data[0].graph.name.length === 1) {
        this.Illicit[1].chartSettings.legend.y = '25%'
      } else if (res.data.result.graph_data[1].data[0].graph.name.length === 2) {
        this.Illicit[1].chartSettings.legend.y = '18%'
      } else if (res.data.result.graph_data[1].data[0].graph.name.length === 3) {
        this.Illicit[1].chartSettings.legend.y = '18%'
        this.Illicit[1].chartSettings.legend.x = '31%'
      }
      return []
    }
  },
  mounted () {
    this.list()
    this.like = 2
  },
  watch: {

  }

}
</script>
<style lang="stylus">
.Sonorous
  width 100%
  .load
    margin-top 10px
  .Limerence
    width 100%
    height 297px
    box-shadow 0px 0px 8px 1px rgba(204, 204, 204, 0.25)
    border-radius 5px
    margin-top 15px
    margin-bottom 20px
    overflow hidden
    li:nth-child(1)
      margin-top 10px
      width 100%
      height 20px
      line-height 20px
      color #333333
      font-size 15px
      display flex
      justify-content space-between
      span:nth-child(2)
        color #999999 !important
        font-size 13px !important
    .summary
      margin-top 20px
  .panli
    width 100%
    overflow hidden
    padding-bottom 20px
    .Hiraeth
      display flex
      justify-content space-between
      span
        color #333333
        font-size 15px
      span:nth-child(2)
        color #999999
        font-size 13px
    .Ineffable
      width 100%
      margin-top 10px
      li
        margin-bottom 10px
      div:nth-child(1)
        color #000000
        font-size 15px
      div:nth-child(2)
        margin-top 5px
        height 20px
        line-height 20px
        font-size 13px
        color #999999
        p:nth-child(1)
          width 30%
          height 20px
          white-space nowrap
          text-overflow ellipsis
          overflow hidden
          float left
        p:nth-child(2)
          width 45%
          height 20px
          white-space nowrap
          text-overflow ellipsis
          overflow hidden
          float left
        p:nth-child(3)
          width 20%
          white-space nowrap
          text-overflow ellipsis
          overflow hidden
          float left
</style>
