<template>
  <fb-page>
    <fb-header slot="header"
               title=""
               fixed>
      <fb-button slot="left"
                 icon="back"
                 @click="globe"
                 size="small"
                 type="primary">
      </fb-button>
    </fb-header>
    <!-- 信息页面进入法官 -->
    <span v-if="$route.query.is.toString() === '0'">
      <div class="JudgePerson">
        <div class="JiGou">
          <div>机构</div>
          <div>
            <input type="text"
                   style="line-height: 20px;"
                   v-model="mechanism"
                   @focus="IPT"
                   @blur="JIGOuShi">
            <i class="iconfont icon-gengduo"></i>
            <ul class="liebiao"
                v-show="label">
              <li v-for="(item,index) in mechLie"
                  :key="index"
                  @click="lieBiao(item.label,item.courtId,index)">{{item.label}}</li>
            </ul>
          </div>
        </div>
        <div class="BianKuang"></div>
        <div class="JiGou Name">
          <div>法官</div>
          <div>
            <p v-for="(item,index) in FBlist"
               :key="index"
               class="DiZao">
              <span>{{item}}</span>
              <i class="iconfont icon-clear"
                 @click="Delete(index)"></i>
            </p>
            <input type="text"
                   v-model="faguan"
                   style="line-height: 20px;"
                   @focus="FB"
                   @keydown="FbDev($event)"
                   @blur="ShiJiao">
            <i class="iconfont icon-gengduo"></i>
            <ul class="liebiao"
                v-show="leball">
              <loading :showflag="search"></loading>
              <li v-for="(item,index) in FGlist"
                  :key="index"
                  @click="FGLIst(item)">{{item}}
              </li>
            </ul>
          </div>
        </div>
        <div class="YuCe"
             v-if="FBlist.length===0">
          <div>律呗预测.本案法官为：</div>
          <div v-for="(item,index) in virtual.slice(0,2)"
               :key="index"
               class="XianShi">
            <!--   v-for="
           (item,index)
           in naval?virtual.filter((item,i) => i < 2):
           MachLie[encounter].judge.filter((item,i) => i < 2)"
               :key="index"-->
            <p @click="faGuan(item.name)">{{item.name}}</p>
            <!--  naval?item.name:item-->
          </div>
          <div>点击选择</div>
        </div>
      </div>
      <div class="Judge"
           v-if="FBlist.length>0">
        <div class="JudgeName">
          <div class="liote">
            <p @click="polit">
              <span>{{this.FBlist[comic]}}</span>
              <i class="iconfont icon-gengduo-copy"></i>
              <ul class="nameflex"
                  v-show="post&&FBlist.length>1">
                <li v-for="(item,index) in FBlist"
                    :key="index"
                    @click="card(index)"
                    :style="{'display':comic===index?'none':''}">{{item}}
                </li>
              </ul>
            </p>
            <p> <span>{{this.mechanism}}</span></p>
          </div>

          <div @click="polie"
               class="liotee">
            <span>{{chicken[vegetable]}}</span>
            <i class="iconfont icon-gengduo-copy">
              <ul class="coffee"
                  v-show="soup&&chicken.length>1">
                <li v-for="(item,index) in chicken"
                    :key="index"
                    :style="{'display':vegetable===index?'none':'block'}"
                    @click="Coke(index)">{{item}}</li>
              </ul>

            </i>
          </div>
        </div>
        <div class="JudgePan">
          <p>总判例数 <span>{{this.debt.total_case}}</span> 篇</p>
          <p>已收录判例该法院排名：<span>{{this.debt.forbid}}</span></p>
        </div>
        <div class="dispute">
          <div class="disputeShu">
            <p>{{this.summer}}</p>
            <p @click="adult">{{this.advertisement}}</p>
          </div>
          <div class="Chart"
               ref="chart"
               v-if="chartSettings.series.data.length>0">
            <ve-ring :settings="chartSettings"
                     :series="chartSettings.series"
                     :legend="chartSettings.legend"
                     :tooltip="chartSettings.tooltip"
                     :colors="['#4B9EFB','#2DC1E7','#C0DCFB']"></ve-ring>
          </div>
          <div v-else
               style="text-align:center;color:#333;margin-top:20px;margin-bottom:20px;font-size:14px">暂无数据</div>
          <div class="FenYe">
            <van-tabs v-model="active"
                      swipeable>
              <van-tab v-for="(item,index) in caseitems"
                       :key="index"
                       :title="item">
                <div v-if="comdata[active]">
                  <component :is="renderName"
                             :data='comdata[active]'></component>
                </div>
                <div v-else>
                  <loading :showflag='1'></loading>
                </div>
              </van-tab>
            </van-tabs>
          </div>
        </div>
        <div class="panli">
          <p class="Hiraeth">
            <span>同案由判例</span>
            <span @click="focus">更多</span>
          </p>
          <ul class="Ineffable">
            <li v-for="(item,index) in Petrichor"
                :key="index">
              <div>
                <span @click="collision(item.doc_id)">{{item.title}}</span>
              </div>
              <div>
                <p>{{item.court}}</p>
                <p>/{{item.case_id}}</p>
                <p>/{{item.pub_date}}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </span>
    <div class="anyou"
         v-if="awkward">
      <caselaw @changeflag="alit"
               :isfb="true"
               :query="query"></caselaw>
    </div>
    <div v-if="Encounter"
         class="enclose">
      <precedent :id="Scan"
                 @changeflag="Scandal"></precedent>
    </div>
    <van-popup v-model="show"
               position="bottom"
               :overlay="true">
      <van-picker show-toolbar
                  :title="message_business"
                  :columns="business"
                  @cancel="onCancel"
                  @confirm="onConfirm"
                  @change="onChange"
                  :item-height="pickCellheight" />
    </van-popup>
  </fb-page>
  <!-- :name="FBlist[comic]"
                         :court="mechanism"
                         :summary="params.summary.reasonRelation"
                         :click="active"
                         :year="chicken[vegetable]" -->
</template>

<script>
import crtp from './infoCompoments/currentPerson'
import lawyer from './infoCompoments/Lawyer'
import One from './infoCompoments/OneEx'
import Two from './infoCompoments/TwoEx'
import VeRing from 'v-charts/lib/ring.common'
import loading from '@/pages/Search/compoments/loading'
import { caseBase, pydataBase } from '~api-config'
import { setTimeout } from 'timers'
import precedent from '@/pages/precedentDetail'
import caselaw from '@/pages/CaseDetail/components/scrollMenu/components/infoTab/caselaw'
// import Qs from 'qs'
const fnobj = {
  infotab: function () {
    if (this.$store.state.caseDetail.adult.id !== this.$route.query.caseId) {
      this.$store.state.caseDetail.adult = {
        // 法官页面
        sort: []
      }
    }
    this.params = this.$store.state.caseDetail.adult
    this.active = this.params.active ? this.params.active : 0
    this.comic = 0
    // if (this.$store.state.caseDetail.adult.sort) {
    //   if (this.$store.state.caseDetail.adult.sort[this.$store.state.caseDetail.adult.sort.length - 1] === 'secure') {
    //     this.virtual = this.$store.state.caseDetail.adult.secure
    //     this.naval = true
    //   } else if (this.$store.state.caseDetail.adult.sort[this.$store.state.caseDetail.adult.sort.length - 1] === 'portable') {
    //     this.encounter = this.$store.state.caseDetail.adult.portable
    //     this.naval = false
    //   }
    // }
    // 请求接口 拿到律师 律所 法院 caseid
    this.$axios.get(`${caseBase}/lts/inf/infOverview?caseId=${this.$route.query.caseId}`).then(res => {
      // vuex保存案件id
      this.$store.state.caseDetail.adult.id = this.$route.query.caseId
      this.workspace = res.data.data
      if (this.workspace.officesInf.judge) {
        if (this.workspace.officesInf.judge.startsWith('[')) {
          this.FBlist = this.workspace.officesInf.judge.slice(1, this.workspace.officesInf.judge.length - 1)
          if (this.FBlist.indexOf(',') > -1) {
            this.FBlist = this.FBlist.split(',')
          } else if (this.FBlist.trim().length > 0) {
            this.FBlist = [this.FBlist]
          } else {
            this.FBlist = []
          }
        }
      }
      if (this.workspace.officesInf.judge === '[]') {
        this.workspace.officesInf.judge = []
      }
      this.workspace.officesInf.judge = this.workspace.officesInf.judge ? this.workspace.officesInf.judge : {}
      if (this.workspace.officesInf.judge === '[]') {
        this.workspace.officesInf.judge = []
      }
      console.log(this.workspace.officesInf.judge, '********* workspace.officesInf.judge *********')
      this.summer = this.workspace.essentialInf.caseReasonName // 案由
      if (this.workspace.officesInf.displayDefaultCourt === 'YES') {
        if (this.workspace.litigantList[0].agentList[0]) {
          const totast = this.$toast({
            mask: true,
            type: 'loading',
            message: '加载中...',
            duration: 0
          })
          /* ?office=${this.workspace.litigantList[0].agentList[0].lawFirmName}&name=${this.workspace.litigantList[0].agentList[0].name} */
          // 第一个原告的第一个律师
          this.$axios.post(`${pydataBase}/api/lawyer/court`, {
            office: this.workspace.litigantList[0].agentList[0].lawFirmName,
            name: this.workspace.litigantList[0].agentList[0].name
          }).then(res => {
            totast.clear()
            if (res.data && res.data.result.length > 0) {
              this.mechanism = res.data.result[0].label
              this.mechLie = res.data.result
              this.scan = Object.assign(this.mechLie)
              this.MachLie = res.data.result
              this.mechLie.forEach(item => {
              })
            } else {
              this.mechanism = this.workspace.officesInf.courtName
            }
          }).catch(err => {
            console.log(err)
          })
        }
      } else {
        // 为no的情况下说明操作过法院
        this.mechanism = this.workspace.officesInf.courtName
      }
      // 不管什么时候都需要请求一次律师常去法院  律师+律所名 请求机构列表
      /* ?office=${this.workspace.litigantList[0].agentList[0].lawFirmName}&name=${this.workspace.litigantList[0].agentList[0].name} */
      if (this.workspace.litigantList[0].agentList[0]) {
        this.$axios.post(`${pydataBase}/api/lawyer/court`, {
          office: this.workspace.litigantList[0].agentList[0].lawFirmName,
          name: this.workspace.litigantList[0].agentList[0].name
        }).then(res => {
          this.mechLie = res.data.result
          this.scan = Object.assign(this.mechLie)
          this.MachLie = res.data.result
          this.mechLie.forEach(item => {
          })
        }).catch(err => {
          console.log(err)
        })
      }
    })

    // this.params.summary = this.params.summary ? this.params.summary : {}
  }
}
export default {
  components: {
    VeRing: VeRing,
    Crtp: crtp,
    Lawyer: lawyer,
    One: One,
    Two: Two,
    loading: loading,
    precedent: precedent,
    caselaw: caselaw
  },
  data () {
    // let num = 0
    this.chartSettings = {
      legend: {
        orient: 'vertical',
        x: '55%',
        y: '15%',
        icon: 'circle',
        textStyle: {
          color: '#333333',
          fontSize: 16,
          fontWeight: 500
        },
        formatter: '{name}'
      },
      series: {
        type: 'pie',
        radius: ['30%', '40%'],
        center: ['23%', '23%'],
        data: [],
        label: {
          position: 'center',
          fontSize: 16,
          color: '#333333',
          formatter: (val, index) => {
            // num += val.data.value
            if (val.dataIndex === 0) {
              return '总计' + this.sum
            } else {
              return ''
            }
          }
        },
        labelLine: {
          smooth: true,
          length: 10,
          length2: 7
        }
      },
      tooltip: {
        formatter: '{b}'
      }
    }
    return {
      summer: '',
      workspace: {},
      query: {},
      awkward: false,
      Encounter: false,
      Scan: null,
      search: 1,
      virtual: [],
      naval: false,
      scan: [],
      encounter: 0,
      debt: {
        forbid: '',
        total_case: '',
        enclose: []
      },
      caseitems: ['一审案例', '二审案例', '律师分析', '当事人分析'],
      message_business: '请选择案由数据',
      business: [],
      advertisement: '查看其他案由数据',
      Petrichor: [],
      show: false,
      changeIndex: 1,
      active: 0,
      mechLie: [],
      MachLie: [],
      label: false,
      leball: false,
      mechanism: '',
      faguan: '',
      FBlist: [],
      FGlist: [],
      post: '',
      comic: null,
      chicken: [],
      soup: false,
      vegetable: 0,
      beans: '',
      BR: false,
      courtId: null,
      comdata: {
        0: null,
        1: null,
        2: null,
        3: null
      },
      sum: 0,
      params: {}
    }
  },
  mounted () {
    // document.querySelector('.load').lastChild.innerText = ''
    // setTimeout(() => {
    //   document.querySelector('.content').style.overflowX = 'visible'
    // }, 1000)
    document.body.onclick = (e) => {
      var haha = document.querySelector('.liote')
      if (haha) {
        if (!haha.contains(e.target)) {
          this.post = false
        }
      }
      var hehe = document.querySelector('.liotee')
      if (hehe) {
        if (!hehe.contains(e.target)) {
          this.soup = false
        }
      }
    }
  },
  computed: {
    pickCellheight () {
      const dpr = document.documentElement.getAttribute('data-dpr') || 1
      return dpr * 44
    },
    renderName () {
      let str = ''
      switch (this.active) {
        case 0:
          str = 'One'
          break
        case 1:
          str = 'Two'
          break
        case 2:
          str = 'Lawyer'
          break
        case 3:
          str = 'Crtp'
          break
      }
      return str
    }
  },
  created () {
    (this.$route.query.is.toString() === '0' ? fnobj.infotab : fnobj.infotab).call(this)
  },
  watch: {

    // 时间选择器
    vegetable (val) {
      const fn = (item) => {
        if (this.mechanism && this.FBlist[this.comic] && this.workspace.officesInf.reasonRelation) {
          const params = {
            name: this.FBlist[this.comic],
            court: this.mechanism,
            summary: this.summer,
            year: this.chicken[val] === '全部' ? '' : this.chicken[val],
            click: item
          }
          this.$axios.post(`${pydataBase}/api/judge/card`, params).then(res => {
            this.sum = 0
            this.chartSettings.series.data = []
            this.comdata[item] = res
            res.data.result.summary_data.graph.datas.forEach((item, index) => {
              this.sum += item
              this.chartSettings.series.data.push({
                name: res.data.result.summary_data.tips[index],
                value: item
              })
            })
            this.debt.forbid = res.data.result.judge_data.rank
            this.debt.total_case = res.data.result.judge_data.total_case
            this.business = res.data.result.judge_data.summary
            if (this.active.toString() === item) {
              this.Petrichor = res.data.result.case_data
            }
            this.beans = this.FBlist[val]
          })
        }
      }
      for (const item in this.comdata) {
        fn(item)
      }
    },
    // 请求自组件数据
    active (val) {
      if (this.mechanism && this.FBlist[this.comic] && this.workspace.officesInf.reasonRelation) {
        const params = {
          name: this.FBlist[this.comic],
          court: this.mechanism,
          summary: this.summer,
          click: val,
          year: this.chicken[this.vegetable] === '全部' ? '' : this.chicken[this.vegetable]
        }
        this.$axios.post(`${pydataBase}/api/judge/card`, params).then(res => {
          this.comdata[val] = res
          // this.chicken = res.data.result.judge_data.years
          // this.chicken.forEach(item => {
          // })
          // this.chicken.unshift('全部')
          this.Petrichor = res.data.result.case_data
          this.beans = this.FBlist[val]
          this.business = res.data.result.judge_data.summary
        })
      }
    },
    // 添加法官时请求接口
    FBlist (val) {
      if (val[this.comic] !== this.beans) {
        if (this.mechanism && this.FBlist[this.comic] && this.workspace.officesInf.reasonRelation) {
          const params = {
            name: this.FBlist[this.comic],
            court: this.mechanism,
            summary: this.summer,
            click: this.active
          }
          this.$axios.post(`${pydataBase}/api/judge/card`, params).then(res => {
            this.comdata[this.active] = res
            this.chicken = res.data.result.judge_data.years
            this.Petrichor = res.data.result.case_data
            this.debt.forbid = res.data.result.judge_data.rank
            this.debt.total_case = res.data.result.judge_data.total_case
            this.business = res.data.result.judge_data.summary
            this.sum = 0
            this.chartSettings.series.data = []
            res.data.result.summary_data.graph.datas.forEach((item, index) => {
              this.sum += item
              this.chartSettings.series.data.push({
                name: res.data.result.summary_data.tips[index],
                value: item
              })
            })
            this.chicken.forEach(item => {
            })
            this.chicken.unshift('全部')
            this.beans = this.FBlist[val]
          })
        }
      }
    },
    // 机构搜索
    mechanism (val) {
      const totast = this.$toast({
        mask: true,
        type: 'loading',
        message: '加载中...',
        duration: 0
      })
      this.$axios.get(`${caseBase}/lts/inf/infOverview?caseId=${this.$route.query.caseId}`).then(res => {
        totast.clear()
        this.workspace = res.data.data
        this.$axios.post(`${pydataBase}/api/judge`, {
          summary: this.workspace.officesInf.reasonRelation,
          court: this.mechanism
        })
          .then(res => {
            if (res.data.result) {
              this.virtual = res.data.result
            }
          })
      })
      if (this.BR) {
        this.$axios.get(`${caseBase}/lts/court/queryCourtByName?name=${val}`).then(res => {
          if (res.data.items) {
            this.mechLie = []
            res.data.items.forEach(item => {
              this.mechLie.push({
                label: item.name,
                cid: item.id
              })
            })
          }
        })
      }
    },
    // 默认进来查看法官列表，包挎select选择
    comic (val) {
      if (this.FBlist[this.comic]) {
        setTimeout(() => {
          const params = {
            name: this.FBlist[this.comic],
            court: this.mechanism,
            summary: this.summer,
            click: this.active,
            year: ''
          }
          this.$axios.post(`${pydataBase}/api/judge/card`, params).then(res => {
            this.sum = 0
            this.chartSettings.series.data = []
            res.data.result.summary_data.graph.datas.forEach((item, index) => {
              this.sum += item
              this.chartSettings.series.data.push({
                name: res.data.result.summary_data.tips[index],
                value: item
              })
            })

            this.debt.forbid = res.data.result.judge_data.rank
            this.debt.total_case = res.data.result.judge_data.total_case
            this.business = res.data.result.judge_data.summary
            this.chicken = res.data.result.judge_data.years
            this.Petrichor = res.data.result.case_data
            this.chicken.forEach(item => {
            })
            this.$set(this.comdata, 0, res)
            this.chicken.unshift('全部')
            this.beans = this.FBlist[val]
            this.vegetable = 0
          })
        }, 300)
      }
    },
    // 法官列表
    faguan (val) {
      if (this.enclose) {
        this.FGlist = Object.assign(this.enclose)
      }
      if (val.trim().length === 0) {
        return false
      }
      this.FGlist = this.FGlist.filter(function (item) {
        return item.indexOf(val.trim()) > -1
      })
    }
  },
  methods: {
    polie () {
      this.soup = !this.soup
      if (this.soup) {
        this.post = false
      }
    },
    polit () {
      if (this.FBlist.length === 1) {
        this.post = false
      } else {
        this.post = !this.post
      }
      if (this.post) {
        this.soup = false
      }
    },
    alit (flag) {
      this.awkward = flag
    },
    collision (id) {
      this.Scan = id
      this.Encounter = true
    },
    Scandal () {
      this.Encounter = false
    },
    globe () {
      this.$router.go(-1)
    },
    focus () {
      this.$store.state.caseDetail.adult.active = this.active
      this.$store.state.caseDetail.adult.fbname = this.FBlist[this.comic]
      this.awkward = true
      this.query = {
        name: this.FBlist[this.comic],
        court: this.mechanism,
        summary: this.workspace.officesInf.reasonRelation,
        year: this.chicken[this.vegetable]
      }
      // this.$router.push({
      //   name: 'caselaw',
      //   query: {
      //     name: this.FBlist[this.comic],
      //     court: this.mechanism,
      //     summary: this.params.summary.reasonRelation,
      //     year: this.chicken[this.vegetable],
      //     is_fb: 1
      //   }
      // })
    },
    onCancel () {
      this.show = false
    },
    onChange (picker, value, index) {
      this.message_business = value
    },
    onConfirm (picker, value, index) {
      this.show = false
      this.advertisement = picker
      this.summer = picker
      const fn = (item) => {
        if (this.mechanism && this.FBlist[this.comic] && this.workspace.officesInf.reasonRelation) {
          const params = {
            name: this.FBlist[this.comic],
            court: this.mechanism,
            summary: this.summer,
            year: this.chicken[this.vegetable] === '全部' ? '' : this.chicken[this.vegetable],
            click: item
          }
          this.$axios.post(`${pydataBase}/api/judge/card`, params).then(res => {
            this.comdata[item] = res
            this.sum = 0
            this.chartSettings.series.data = []
            res.data.result.summary_data.graph.datas.forEach((item, index) => {
              this.sum += item
              this.chartSettings.series.data.push({
                name: res.data.result.summary_data.tips[index],
                value: item
              })
            })
            // this.comdata[item] = res.data.result.summary_data.graph.datas.forEach((item, index) => {
            //   this.sum += item
            //   this.chartSettings.series.data.push({
            //     name: res.data.result.summary_data.tips[index],
            //     value: item
            //   })
            // })
            this.debt.forbid = res.data.result.judge_data.rank
            this.debt.total_case = res.data.result.judge_data.total_case
            this.business = res.data.result.judge_data.summary
            // this.chicken.forEach(item => {
            // })
            // this.chicken.unshift('全部')
            // this.beans = this.FBlist[val]
            if (this.active.toString() === item) {
              this.Petrichor = res.data.result.case_data
            }
          })
        }
      }
      for (const item in this.comdata) {
        fn(item)
      }
    },
    // select选择法官
    card (index) {
      setTimeout(() => {
        this.post = false
      }, 100)

      this.comic = index
    },
    Coke (index) {
      this.vegetable = index
    },
    adult () {
      this.show = true
    },
    // 通过案由+法院搜索法官的点击事件
    FGLIst (val) {
      this.leball = false
      if (this.FBlist.length === 3) {
        this.$notify('最多能添加三个法官')
        return false
      }
      if (this.FBlist.indexOf(val.trim()) > -1) {
        this.$notify('已添加该法官')
        return false
      } else {
        this.FBlist.push(val)
        this.added(true)
      }
      this.faguan = ''
      this.FGlist = []
    },
    // 键盘按下生成法官事件
    FbDev (e) {
      if (e.keyCode === 13 && this.faguan.trim().length > 0) {
        this.faGuan(this.faguan)
        this.faguan = ''
      }
    },
    // 法官列表聚焦的时候显示搜索框
    FB () {
      this.leball = true
      this.$axios.post(`${pydataBase}/api/judge`, {
        summary: this.workspace.officesInf.reasonRelation,
        court: this.mechanism
      }).then(res => {
        this.search = 2
        this.FGlist = []
        if (res.data && res.data.result.length > 0) {
          res.data.result.forEach(item => {
            if (this.FBlist.indexOf(item) > -1) {
              return
            }
            this.FGlist.push(item.name)
            this.enclose = Object.assign(this.FGlist)
          })
        }
      })
    },
    // 法官失焦 搜索框关闭
    ShiJiao () {
      setTimeout(() => {
        this.leball = false
      }, 100)
      if (this.faguan !== '') {
        this.FBlist.push(this.faguan)
        this.added(true)
      }
    },
    // 添加法官
    added (type) {
      this.$axios.post(`${caseBase}/lts/case_judge/save`, { caseId: this.workspace.officesInf.caseId, courtId: this.courtId, courtName: this.mechanism, judgeNames: this.FBlist }).then(res => {
        if (res.data.code === '200') {
          this.$notify({
            message: type ? '添加法官成功' : '删除法官成功',
            duration: 1000,
            background: '#1989fa'
          })
        }
        this.faguan = ''
        // this.$store.state.caseDetail.adult.summary.judge = '['
        // this.FBlist.forEach((item, index) => {
        //   this.$store.state.caseDetail.adult.summary.judge += (item.trim().toString() + (index === this.FBlist.length - 1 ? '' : ','))
        // })
        // this.$store.state.caseDetail.adult.summary.judge += ']'
      }).catch(err => {
        console.log(err)
      })
    },
    // 删除法官
    Delete (num) {
      this.FBlist.splice(num, 1)
      while (!this.FBlist[this.comic] && this.comic > 0) {
        this.comic--
      }
      this.added()
    },
    // 机构列表点击赋值，并且关闭搜索框
    lieBiao (text, id, index) {
      if (this.FBlist) {
        this.FBlist = []
      }
      this.encounter = index
      this.mechanism = text
      this.label = false
      this.courtId = id
      this.$store.state.caseDetail.adult.count = text
      if (this.scan !== this.mechLie) {
        // 当为用户搜索时，获取法官数组
        this.$axios.post(`${pydataBase}/api/judge`, {
          summary: this.workspace.essentialInf.caseReasonName,
          court: text
        }).then(res => {
          this.virtual = [...res.data.result]
          // this.naval = true
          // this.$set(this.$store.state.caseDetail.adult, 'secure', this.virtual)
          // this.$store.state.caseDetail.adult.sort.push('secure')
        })
        // 用户搜索时，拿法院id
        this.mechLie.forEach((item, index) => {
          if (item.label === text) {
            this.$axios.post(`${caseBase}/lts/case_judge/save`, { caseId: this.workspace.officesInf.caseId, courtId: item.cid, courtName: text, judgeNames: [''] }).then(res => {
            })
          }
        })
      } else {
        this.$axios.post(`${caseBase}/lts/case_judge/save`, { caseId: this.workspace.officesInf.caseId, courtId: this.courtId, courtName: text, judgeNames: [''] }).then(res => {
          // this.naval = false
          // this.$set(this.$store.state.caseDetail.adult, 'portable', this.encounter)
          // this.$store.state.caseDetail.adult.sort.push('portable')
        })
      }
      for (let i = 0; i < this.scan.length; i++) {
        if (this.scan[i].label.indexOf(text.trim()) > -1) {
          return false
        }
      }
    },
    // 机构ju焦显示搜索框
    IPT () {
      this.label = true
      this.BR = true
    },
    // 机构失焦 搜索框消失
    JIGOuShi () {
      this.BR = false
      setTimeout(() => {
        this.label = false
      }, 100)
    },
    // 律呗预测
    faGuan (text) {
      if (this.FBlist.length === 3) {
        this.$notify('最多能添加三个法官')
        return false
      }
      if (this.FBlist.indexOf(text.trim()) > -1) {
        this.$notify('已添加该法官')
        return false
      } else {
        this.FBlist.push(text)
      }
      this.added(true)
    }
  }

}
</script>

<style lang="stylus">
.anyou
  width 100%
  height 100%
  position fixed
  top 0px
  left 0px
  background #ffffff
  z-index 99999
.enclose
  width 100%
  height 100%
  position fixed
  left 0px
  top 0px
  background #ffffff
  z-index 9999
.JudgePerson
  width 100%
  .Name
    border-bottom 1px solid #F3F4F7
    .DiZao
      height 35px
      padding-left 5px
      padding-right 5px
      overflow hidden
      line-height 35px
      align-self center
      text-align left
      text-indent 5px
      min-width 80px
      background #f7f7f7
      margin-right 10px
      position relative
      i
        display inline-block
        position absolute
        top 0px
        right 5px
      span
        display inline-block
        width 70%
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
  .YuCe
    width 92%
    height 25px
    line-height 25px
    font-size 12px
    color #666666
    display flex
    justify-content space-between
    margin 10px auto 0px
    margin-bottom 10px
    .XianShi
      max-width 80px
      height 25px
      background rgba(247, 247, 247, 1)
      line-height 25px
      text-align center
      border-radius 13px
      color #000000
      font-size 14px
      padding-left 10px
      padding-right 10px
      white-space nowrap
      overflow hidden
      text-overflow ellipsis
      p
        width 100%
        height 100%
        white-space nowrap
        overflow hidden
        text-overflow ellipsis
  .JiGou
    width 92%
    height 51px
    line-height 51px
    display flex
    justify-content space-between
    margin 0px auto
    div:nth-child(1)
      font-size 15px
      color #000000
      width 20%
    div:nth-child(2)
      width 80%
      height 100%
      position relative
      display flex
      input
        height 90%
        font-size 15px
        flex-grow 1
        align-self center
        border none
        width 20%
      .liebiao
        width 100%
        position absolute
        top 56px
        left 0px
        background #ffffff
        box-shadow 0px 4px 8px 1px rgba(190, 190, 190, 0.64)
        border-radius 5px
        z-index 99999999999
        padding-bottom 30px
        overflow auto
        height 200px
        .load
          position absolute
          width 100%
        li
          width 100%
          height 40px
          line-height 40px
          font-size 15px
          color #666666
          text-indent 15px
          margin-top 5px
          border-bottom 1px solid #eee
  .BianKuang
    width 100%
    height 5px
    height 5px
    background rgba(239, 239, 239, 1)
.Judge
  width 92%
  margin 0px auto
  .JudgeName
    width 100%
    max-height 50px
    line-height 50px
    justify-content space-between
    font-size 15px
    border-bottom 1px solid #f2f2f2
    div:nth-child(1)
      color #000000
      display flex
      float left
      width 80%
      p:nth-child(1)
        position relative
        i
          color #cccccc
          margin-left 5px
        .nameflex
          width 100%
          padding-bottom 5px
          position absolute
          left 0px
          top 50px
          z-index 999
          background #ffffff
          text-align center
          box-shadow 0px 0px 8px 3px rgba(240, 240, 240, 1)
          border-radius 3px
          left 0px
          top 50px
          li
            width 100%
            height 40px
            line-height 40px
            text-align center
      p:nth-child(2)
        margin-left 10px
        color #666666
        font-size 14px
        align-self center
        max-width 70%
        display flex
        padding-top 5px
        span
          display inline-block
          height 20px
          font-size 14px
          line-height 20px
          overflow hidden
          text-overflow ellipsis
          white-space nowrap
          width 100%
    div:nth-child(2)
      float left
      width 20%
      position relative
    span
      margin-right 5px
      color #333333
      align-self center
    i
      color #cccccc
      align-self center
    .coffee
      width 100%
      top 50px
      left 0px
      padding-bottom 5px
      box-shadow 0px 0px 8px 3px rgba(240, 240, 240, 1)
      border-radius 3px
      background #ffffff
      z-index 999
      color #333
      position absolute
      li
        width 100%
        height 40px
        line-height 40px
        text-align center
    .newspaper
      position absolute
      width 100%
      background #ffffff
      box-shadow 0px 0px 8px 3px rgba(240, 240, 240, 1)
      border-radius 3px
      text-align center
      li
        width 100%
        height 40px
        line-height 40px
        text-align center
  .JudgePan
    width 100%
    font-size 14px
    color #666666
    margin-top 10px
    p
      line-height 30px
      span
        font-size 16px
        color #000000
    p:nth-child(2)
      font-size 15px
      color #999999
  .dispute
    width 100%
    margin-top 10px
    .FenYe
      width 100%
      margin-top 10px
      .van-tabs__line
        background-color #4b9efb !important
    .Chart
      width 100%
      height 170px
      overflow hidden
    .disputeShu
      display flex
      justify-content space-between
      font-size 18px
      color #333333
      width 100%
      height 20px
      line-height 20px
      font-size 18px
      color #333333
      p:nth-child(1)
        width 66%
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
      p:nth-child(2)
        width 36%
        color #999999
        font-size 15px
  .panli
    width 100%
    overflow hidden
    padding-bottom 20px
    .Hiraeth
      display flex
      justify-content space-between
      span
        color #333333
        font-size 15px
      span:nth-child(2)
        color #999999
        font-size 13px
    .Ineffable
      width 100%
      margin-top 10px
      li
        margin-bottom 10px
      div:nth-child(1)
        color #000000
        font-size 15px
      div:nth-child(2)
        margin-top 5px
        height 20px
        line-height 20px
        font-size 12px
        color #999999
        p:nth-child(1)
          max-width 27%
          height 20px
          white-space nowrap
          text-overflow ellipsis
          overflow hidden
          float left
        p:nth-child(2)
          max-width 50%
          height 20px
          white-space nowrap
          text-overflow ellipsis
          overflow hidden
          float left
        p:nth-child(3)
          max-width 23%
          white-space nowrap
          text-overflow ellipsis
          overflow hidden
          float left
</style>
