var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        { attrs: { slot: "header", title: "", fixed: "" }, slot: "header" },
        [
          _c("fb-button", {
            attrs: {
              slot: "left",
              icon: "back",
              size: "small",
              type: "primary",
            },
            on: { click: _vm.globe },
            slot: "left",
          }),
        ],
        1
      ),
      _vm.$route.query.is.toString() === "0"
        ? _c("span", [
            _c("div", { staticClass: "JudgePerson" }, [
              _c("div", { staticClass: "JiGou" }, [
                _c("div", [_vm._v("机构")]),
                _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.mechanism,
                        expression: "mechanism",
                      },
                    ],
                    staticStyle: { "line-height": "20px" },
                    attrs: { type: "text" },
                    domProps: { value: _vm.mechanism },
                    on: {
                      focus: _vm.IPT,
                      blur: _vm.JIGOuShi,
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.mechanism = $event.target.value
                      },
                    },
                  }),
                  _c("i", { staticClass: "iconfont icon-gengduo" }),
                  _c(
                    "ul",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.label,
                          expression: "label",
                        },
                      ],
                      staticClass: "liebiao",
                    },
                    _vm._l(_vm.mechLie, function (item, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          on: {
                            click: function ($event) {
                              return _vm.lieBiao(
                                item.label,
                                item.courtId,
                                index
                              )
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.label))]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              _c("div", { staticClass: "BianKuang" }),
              _c("div", { staticClass: "JiGou Name" }, [
                _c("div", [_vm._v("法官")]),
                _c(
                  "div",
                  [
                    _vm._l(_vm.FBlist, function (item, index) {
                      return _c("p", { key: index, staticClass: "DiZao" }, [
                        _c("span", [_vm._v(_vm._s(item))]),
                        _c("i", {
                          staticClass: "iconfont icon-clear",
                          on: {
                            click: function ($event) {
                              return _vm.Delete(index)
                            },
                          },
                        }),
                      ])
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.faguan,
                          expression: "faguan",
                        },
                      ],
                      staticStyle: { "line-height": "20px" },
                      attrs: { type: "text" },
                      domProps: { value: _vm.faguan },
                      on: {
                        focus: _vm.FB,
                        keydown: function ($event) {
                          return _vm.FbDev($event)
                        },
                        blur: _vm.ShiJiao,
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.faguan = $event.target.value
                        },
                      },
                    }),
                    _c("i", { staticClass: "iconfont icon-gengduo" }),
                    _c(
                      "ul",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.leball,
                            expression: "leball",
                          },
                        ],
                        staticClass: "liebiao",
                      },
                      [
                        _c("loading", { attrs: { showflag: _vm.search } }),
                        _vm._l(_vm.FGlist, function (item, index) {
                          return _c(
                            "li",
                            {
                              key: index,
                              on: {
                                click: function ($event) {
                                  return _vm.FGLIst(item)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item) + " ")]
                          )
                        }),
                      ],
                      2
                    ),
                  ],
                  2
                ),
              ]),
              _vm.FBlist.length === 0
                ? _c(
                    "div",
                    { staticClass: "YuCe" },
                    [
                      _c("div", [_vm._v("律呗预测.本案法官为：")]),
                      _vm._l(_vm.virtual.slice(0, 2), function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "XianShi" },
                          [
                            _c(
                              "p",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.faGuan(item.name)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.name))]
                            ),
                          ]
                        )
                      }),
                      _c("div", [_vm._v("点击选择")]),
                    ],
                    2
                  )
                : _vm._e(),
            ]),
            _vm.FBlist.length > 0
              ? _c("div", { staticClass: "Judge" }, [
                  _c("div", { staticClass: "JudgeName" }, [
                    _c("div", { staticClass: "liote" }, [
                      _c("p", { on: { click: _vm.polit } }, [
                        _c("span", [_vm._v(_vm._s(this.FBlist[_vm.comic]))]),
                        _c("i", { staticClass: "iconfont icon-gengduo-copy" }),
                        _c(
                          "ul",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.post && _vm.FBlist.length > 1,
                                expression: "post&&FBlist.length>1",
                              },
                            ],
                            staticClass: "nameflex",
                          },
                          _vm._l(_vm.FBlist, function (item, index) {
                            return _c(
                              "li",
                              {
                                key: index,
                                style: {
                                  display: _vm.comic === index ? "none" : "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.card(index)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item) + " ")]
                            )
                          }),
                          0
                        ),
                      ]),
                      _c("p", [_c("span", [_vm._v(_vm._s(this.mechanism))])]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "liotee", on: { click: _vm.polie } },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.chicken[_vm.vegetable])),
                        ]),
                        _c("i", { staticClass: "iconfont icon-gengduo-copy" }, [
                          _c(
                            "ul",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.soup && _vm.chicken.length > 1,
                                  expression: "soup&&chicken.length>1",
                                },
                              ],
                              staticClass: "coffee",
                            },
                            _vm._l(_vm.chicken, function (item, index) {
                              return _c(
                                "li",
                                {
                                  key: index,
                                  style: {
                                    display:
                                      _vm.vegetable === index
                                        ? "none"
                                        : "block",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.Coke(index)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(item))]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "JudgePan" }, [
                    _c("p", [
                      _vm._v("总判例数 "),
                      _c("span", [_vm._v(_vm._s(this.debt.total_case))]),
                      _vm._v(" 篇"),
                    ]),
                    _c("p", [
                      _vm._v("已收录判例该法院排名："),
                      _c("span", [_vm._v(_vm._s(this.debt.forbid))]),
                    ]),
                  ]),
                  _c("div", { staticClass: "dispute" }, [
                    _c("div", { staticClass: "disputeShu" }, [
                      _c("p", [_vm._v(_vm._s(this.summer))]),
                      _c("p", { on: { click: _vm.adult } }, [
                        _vm._v(_vm._s(this.advertisement)),
                      ]),
                    ]),
                    _vm.chartSettings.series.data.length > 0
                      ? _c(
                          "div",
                          { ref: "chart", staticClass: "Chart" },
                          [
                            _c("ve-ring", {
                              attrs: {
                                settings: _vm.chartSettings,
                                series: _vm.chartSettings.series,
                                legend: _vm.chartSettings.legend,
                                tooltip: _vm.chartSettings.tooltip,
                                colors: ["#4B9EFB", "#2DC1E7", "#C0DCFB"],
                              },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "center",
                              color: "#333",
                              "margin-top": "20px",
                              "margin-bottom": "20px",
                              "font-size": "14px",
                            },
                          },
                          [_vm._v("暂无数据")]
                        ),
                    _c(
                      "div",
                      { staticClass: "FenYe" },
                      [
                        _c(
                          "van-tabs",
                          {
                            attrs: { swipeable: "" },
                            model: {
                              value: _vm.active,
                              callback: function ($$v) {
                                _vm.active = $$v
                              },
                              expression: "active",
                            },
                          },
                          _vm._l(_vm.caseitems, function (item, index) {
                            return _c(
                              "van-tab",
                              { key: index, attrs: { title: item } },
                              [
                                _vm.comdata[_vm.active]
                                  ? _c(
                                      "div",
                                      [
                                        _c(_vm.renderName, {
                                          tag: "component",
                                          attrs: {
                                            data: _vm.comdata[_vm.active],
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      [
                                        _c("loading", {
                                          attrs: { showflag: 1 },
                                        }),
                                      ],
                                      1
                                    ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "panli" }, [
                    _c("p", { staticClass: "Hiraeth" }, [
                      _c("span", [_vm._v("同案由判例")]),
                      _c("span", { on: { click: _vm.focus } }, [
                        _vm._v("更多"),
                      ]),
                    ]),
                    _c(
                      "ul",
                      { staticClass: "Ineffable" },
                      _vm._l(_vm.Petrichor, function (item, index) {
                        return _c("li", { key: index }, [
                          _c("div", [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.collision(item.doc_id)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.title))]
                            ),
                          ]),
                          _c("div", [
                            _c("p", [_vm._v(_vm._s(item.court))]),
                            _c("p", [_vm._v("/" + _vm._s(item.case_id))]),
                            _c("p", [_vm._v("/" + _vm._s(item.pub_date))]),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.awkward
        ? _c(
            "div",
            { staticClass: "anyou" },
            [
              _c("caselaw", {
                attrs: { isfb: true, query: _vm.query },
                on: { changeflag: _vm.alit },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.Encounter
        ? _c(
            "div",
            { staticClass: "enclose" },
            [
              _c("precedent", {
                attrs: { id: _vm.Scan },
                on: { changeflag: _vm.Scandal },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom", overlay: true },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c("van-picker", {
            attrs: {
              "show-toolbar": "",
              title: _vm.message_business,
              columns: _vm.business,
              "item-height": _vm.pickCellheight,
            },
            on: {
              cancel: _vm.onCancel,
              confirm: _vm.onConfirm,
              change: _vm.onChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }