var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "Lawyer" }, [
        _c(
          "p",
          {
            style: {
              "border-color": _vm.Iridescent ? "#4b9efb" : "#666666",
              color: _vm.Iridescent ? "#4b9efb" : "#666666",
            },
            on: {
              click: function ($event) {
                _vm.Iridescent = true
              },
            },
          },
          [_vm._v("律所")]
        ),
        _c(
          "p",
          {
            style: {
              "border-color": !_vm.Iridescent ? "#4b9efb" : "#666666",
              color: !_vm.Iridescent ? "#4b9efb" : "#666666",
            },
            on: {
              click: function ($event) {
                _vm.Iridescent = false
              },
            },
          },
          [_vm._v("律师")]
        ),
      ]),
      _c("loading", { attrs: { showflag: _vm.Eraser } }),
      _c("div", { staticClass: "LawyerFen_contant" }, [
        _c("ul", { staticClass: "LawyerFen" }, [
          _c(
            "li",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.Iridescent,
                  expression: "Iridescent",
                },
              ],
            },
            [
              _vm._l(
                typeof this.eraser.showflag == "boolean"
                  ? this.eraser.showflag
                    ? this.eraser
                    : this.eraser.slice(0, 3)
                  : [],
                function (item, index) {
                  return _c("span", { key: index }, [
                    _c("div", { staticClass: "IGH" }, [
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(item.name) + " " + _vm._s(item.num)),
                        ]),
                        _c("i", { staticClass: "iconfont icon-gengduo-copy" }),
                      ]),
                      _c(
                        "div",
                        _vm._l(item.data, function (items, indexs) {
                          return _c("span", { key: indexs }, [
                            _vm._v(
                              _vm._s(items.sub_name) + " " + _vm._s(items.num)
                            ),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ])
                }
              ),
              this.eraser.length > 3
                ? _c(
                    "div",
                    {
                      staticClass: "encounter",
                      on: {
                        click: function ($event) {
                          return _vm.changeflag()
                        },
                      },
                    },
                    [_vm._v(_vm._s(this.eraser.showflag ? "收起" : "查看全部"))]
                  )
                : _vm._e(),
            ],
            2
          ),
          _c(
            "li",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.Iridescent,
                  expression: "!Iridescent",
                },
              ],
            },
            [
              _vm._l(
                typeof this.mobile.flag == "boolean"
                  ? this.mobile.flag
                    ? this.mobile
                    : this.mobile.slice(0, 3)
                  : [],
                function (item, index) {
                  return _c("span", { key: index }, [
                    _c("div", { staticClass: "TFG" }, [
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(item.name) + " " + _vm._s(item.num)),
                        ]),
                        _c("i", { staticClass: "iconfont icon-gengduo-copy" }),
                      ]),
                      _c(
                        "div",
                        _vm._l(item.data, function (items, indexs) {
                          return _c("span", { key: indexs }, [
                            _vm._v(
                              _vm._s(items.sub_name) + " " + _vm._s(items.num)
                            ),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ])
                }
              ),
              this.mobile.length > 3
                ? _c(
                    "div",
                    {
                      staticClass: "encounter",
                      on: {
                        click: function ($event) {
                          return _vm.debtflag()
                        },
                      },
                    },
                    [_vm._v(_vm._s(this.mobile.flag ? "收起" : "查看全部"))]
                  )
                : _vm._e(),
            ],
            2
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }