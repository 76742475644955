var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "LawyerFen_contant" }, [
      _c(
        "ul",
        { staticClass: "LawyerFen" },
        [
          _c("loading", { attrs: { showflag: _vm.like } }),
          _c(
            "li",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.Iridescent,
                  expression: "Iridescent",
                },
              ],
            },
            _vm._l(_vm.crayon, function (item, index) {
              return _c("span", { key: index }, [
                _c("div", { staticClass: "IGH" }, [
                  _c("div", [
                    _c("span", [
                      _vm._v(_vm._s(item.name) + " " + _vm._s(item.num)),
                    ]),
                    _c("i", { staticClass: "iconfont icon-gengduo-copy" }),
                  ]),
                  _c(
                    "div",
                    _vm._l(item.data, function (items, indexs) {
                      return _c("span", { key: indexs }, [
                        _vm._v(
                          _vm._s(items.sub_name) + " " + _vm._s(items.num)
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
              ])
            }),
            0
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }