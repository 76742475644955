var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Content" },
    [
      _c("loading", { attrs: { showflag: _vm.onion } }),
      _vm._l(_vm.Petrichor, function (item, index) {
        return _c("ul", { key: index, staticClass: "biscuit" }, [
          _c("li", [_c("span", [_vm._v(_vm._s(item.title))])]),
          _c(
            "li",
            [
              _c("ve-histogram", {
                attrs: {
                  tooltip: item.tooltip,
                  "tooltip-visible": false,
                  grid: item.grid,
                  xAxis: item.xAxis,
                  yAxis: item.yAxis,
                  series: item.series,
                  settings: item,
                  colors: item.color,
                },
              }),
            ],
            1
          ),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }